<script setup>
import { Edit, Filter, Plus, Setting } from '@element-plus/icons-vue'
import TimeAgo from 'javascript-time-ago'
import { computed, defineProps, inject, ref, watch } from 'vue'
import HipoQueryTooltip from '@/components/pages/gameplay/hipo/HipoQueryTooltip.vue'
import HiPoSegmentsDrawer from '@/components/pages/gameplay/hipo/HipoSegmentsDrawer.vue'
import HiPoQueryDrawer from '@/components/pages/gameplay/hipo/HipoQueryDrawer.vue'
import { globalProperties as app } from '!/plugins/utilities.js'

const props = defineProps({
  game: {
    type: String,
    default: ''
  },
  apiUrl: {
    type: String,
    default: ''
  },
  groupLabel: {
    type: String,
    default: ''
  }
})

const form = inject('crudForm', {})

function initDataStruct() {
  return {
    abResultsData: {
      segments: [],
      settings: {},
      segmentsList: [],
      queriesList: []
    },
    segmentTabsModel: 0,
    abFilters: {
      start: undefined,
      end: undefined,
      query: undefined
    },
    abFiltersHelpers: {
      selectorQuery: undefined
    },
    loading: {
      abResults: false
    },
    showSegmentsDrawer: false,
    showQueryDrawer: false,
    editQuery: {}
  }
}

const abData = ref(initDataStruct())

function loadAbResults(callBack, querySettings) {
  abData.value.loading.abResults = true
  const requestData = { id: form.value.id, game: props.game, control: form.value.control, ...abData.value.abFilters }
  if (querySettings) {
    delete requestData.query
  }
  app.$axios
    .get('/admin/api/hipo/test-results/', { params: requestData })
    .then((data) => {
      const segmentsList = {}
      const queriesList = {}
      let referQuery
      Object.entries(data?.data?.queries ?? {}).forEach(([_, qData]) => {
        if (qData.Segment) {
          segmentsList[qData.ID] = qData
        } else {
          queriesList[qData.ID] = { ...qData, value: qData.ID, label: qData.Title }
          if (querySettings?.queryTitle?.length && qData.Title === querySettings.queryTitle) {
            referQuery = qData
          }
        }
      })
      abData.value.abResultsData.queriesList = Object.values(queriesList).sort((a, b) => {
        const aLabel = a.label.toUpperCase()
        const bLabel = b.label.toUpperCase()
        if (aLabel < bLabel)
          return -1
        if (aLabel > bLabel)
          return 1
        return 0
      })
      if (querySettings) {
        if (querySettings?.queryId && querySettings?.removedQuery && abData.value.abFiltersHelpers.selectorQuery === querySettings?.queryId) { // only when is removed selected query
          abData.value.abFilters.query = undefined
        } else if (!querySettings.queryId && querySettings.queryTitle?.length && querySettings?.removedQuery === false) {
          abData.value.abFilters.query = referQuery?.Query
          abData.value.abFiltersHelpers.selectorQuery = referQuery.ID
        } else if (querySettings.queryId && querySettings?.removedQuery === false && querySettings.queryId === abData.value.abFiltersHelpers.selectorQuery) {
          abData.value.abFilters.query = referQuery?.Query
        }
        return false // only refresh query list
      }

      abData.value.abResultsData.segments = []
      const segments = data?.data?.segments ?? {}
      const segmentsArr = []
      Object.entries(segments).forEach(([segmentKey, segmentData]) => {
        segmentData.CalculatedFormat = segmentData.Calculated ? new TimeAgo('en-US').format(segmentData.Calculated) : ''
        segmentData.KPIs = Object.entries(segmentData.KPIs || {}).map(([kpiKey, kpiData]) => {
          kpiData.key = kpiKey
          kpiData.Groups = Object.fromEntries((kpiData.Groups ?? []).map((kpiData) => {
            if (kpiData.Continuous) {
              kpiData.ValueFormated = app.$utils.numberFormat(kpiData.Value)
            } else {
              kpiData.ValueFormated = `${app.$utils.numberFormat(kpiData.Value * 100)}%`
            }
            return [`${kpiData.Group}`, kpiData]
          }))
          Object.entries(kpiData.Groups).forEach(([_, grData]) => {
            grData.diff = ''
            grData.diffFormated = ''
            if (grData.Group !== form.value.control && kpiData.Groups?.[form.value.control] && kpiData.Groups?.[form.value.control].Value !== grData.Value) {
              const controlValue = kpiData.Groups?.[form.value.control].Value
              grData.diff = (grData.Value - controlValue) / (controlValue || 1) * 100
              grData.diffFormated = `${grData.diff > 0 ? '+' : ''}${app.$utils.numberFormat(grData.diff)}%`
            }
          })
          return kpiData
        }).sort((a, b) => a.Order - b.Order)
        segmentData.PlayersCompare = {}
        Object.entries(segmentData.Players ?? {}).forEach(([grPlayer, qtPlayer]) => {
          segmentData.PlayersCompare[grPlayer] = {
            qtFormat: app.$utils.numberFormat(qtPlayer),
            diff: '',
            diffFormated: ''
          }
          const grPlayerNumber = grPlayer * 1
          if (grPlayerNumber !== form.value.control && segmentData.Players?.[form.value.control] !== undefined && segmentData.Players?.[form.value.control] !== qtPlayer) {
            const controlValue = segmentData.Players?.[form.value.control]
            segmentData.PlayersCompare[grPlayer].diff = (qtPlayer - controlValue) / (controlValue || 1) * 100
            segmentData.PlayersCompare[grPlayer].diffFormated = `${segmentData.PlayersCompare[grPlayer].diff > 0 ? '+' : ''}${app.$utils.numberFormat(segmentData.PlayersCompare[grPlayer].diff)}%`
          }
        })
        let segmentLabel = ''
        if (segmentKey === '0') {
          segmentLabel = 'Default'
        } else {
          segmentLabel = segmentsList?.[segmentKey]?.Title ?? `Segment id ${segmentKey}`
        }
        segmentsArr.push({ ...segmentData, key: Number.parseInt(segmentKey), label: segmentLabel, query: segmentsList?.[segmentKey]?.Query ?? '' })
      })
      abData.value.abResultsData.segments = segmentsArr.sort((a, b) => {
        const aLabel = a.label.toUpperCase()
        const bLabel = b.label.toUpperCase()
        if (!a.key || !b.key) {
          return 1
        }
        if (aLabel < bLabel)
          return -1
        if (aLabel > bLabel)
          return 1
        return 0
      })
      // abData.value.segmentTabsModel = abData.value.abResultsData?.segments?.[0]?.key ?? 0

      abData.value.abResultsData.segmentsList = Object.values(segmentsList)
      abData.value.abResultsData.settings = data?.data?.settings ?? {}
    })
    .catch(app.$utils.catchError)
    .then(() => {
      abData.value.loading.abResults = false
      if (callBack) {
        callBack()
      }
    })
}

watch(() => form.value.id, (id) => {
  if (id && form.value.testType === 'ABTest') {
    initDataStruct()
    loadAbResults(null)
  }
})

const activeGroup = computed(() => {
  const data = []
  for (let i = 1; i <= 4; i++) {
    if (form.value.segmentPercentage[i - 1]) {
      data.push(i)
    }
  }
  return data
})

const kpiOrderGroup = computed(() => {
  if (!form.value.control) {
    return activeGroup.value
  }
  const kpiGroups = []
  for (let i = 1; i <= 4; i++) {
    if (form.value.segmentPercentage[i - 1] || i === form.value.control) {
      kpiGroups.push(i)
    }
  }
  return kpiGroups.sort((a) => {
    if (a === form.value.control) {
      return -1
    }
    return 0
  })
})

const segmentsKPIs = computed(() => {
  return abData.value.abResultsData.segments
})

function editQuery(queryData) {
  abData.value.editQuery = queryData
  abData.value.showQueryDrawer = true
}

function onRefreshQueries(data) {
  abData.value.editQuery = {}
  loadAbResults(data.callBack, data.settings)
}

const queriesOptionsById = computed(() => {
  return Object.fromEntries(abData.value.abResultsData.queriesList.map((item) => {
    return [item.ID, item]
  }))
})

watch(() => abData.value.abFilters.query, (qNewValue) => {
  if ((qNewValue?.length && queriesOptionsById.value?.[abData.value.abFiltersHelpers.selectorQuery]?.Query !== qNewValue) || qNewValue === undefined) {
    abData.value.abFiltersHelpers.selectorQuery = undefined
  }
})

watch(() => abData.value.abFiltersHelpers.selectorQuery, (newValue, oldValue) => {
  if (newValue) {
    abData.value.abFilters.query = queriesOptionsById.value?.[newValue]?.Query
  } else if (queriesOptionsById.value?.[oldValue]?.Query === abData.value.abFilters.query) {
    abData.value.abFilters.query = undefined
  }
})
// el.scrollWidth, el.clientWidth
</script>

<template>
  <el-tab-pane
    v-if="form.testType === 'ABTest' && !['Idea', 'Voting', 'Canceled'].includes(form.status)"
    name="ab-bi"
  >
    <template #label>
      AB results
      <icon-ify
        v-if="segmentsKPIs?.length"
        icon="mdi:check-bold"
        class="inline-block"
      />
    </template>
    <template #default>
      <template
        v-if="kpiOrderGroup?.length && segmentsKPIs?.length"
      >
        <el-row :gutter="40">
          <crud-field-textarea
            api-field-name="query"
            :form="abData.abFilters"
            :textarea-rows="3"
            :sm="10"
            class="query-field group"
          >
            <template #label>
              <div class="flex justify-between items-center">
                <div class="w-2/5">
                  Query <HipoQueryTooltip />
                </div>
                <div class="w-3/5">
                  <crud-field-select
                    api-field-name="selectorQuery"
                    :form="abData.abFiltersHelpers"
                    empty-label
                    filter-mode
                    class="pb-1"
                  >
                    <template #options>
                      <el-option
                        v-for="item in abData.abResultsData.queriesList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        class="pr-0.5"
                      >
                        <div class="flex justify-between gap-x-2">
                          <el-tooltip
                            :show-after="300"
                            placement="bottom-start"
                            :show-arrow="false"
                            :offset="0"
                          >
                            <template #content>
                              Query: {{ item.Query }}
                            </template>
                            <div class="flex-grow">
                              {{ item.label }}
                            </div>
                          </el-tooltip>
                          <div @click.stop>
                            <el-button
                              class="gs-btn-outlined-primary-light gs-height-related-sm font-related-sm"
                              :icon="Edit"
                              @click="editQuery(item)"
                            />
                          </div>
                        </div>
                      </el-option>
                    </template>
                    <template #footer>
                      <el-button
                        class="gs-btn-outlined-primary gs-height-related-sm font-related-lg"
                        :icon="Plus"
                        @click="editQuery({ Query: abData.abFilters.query })"
                      >
                        ADD NEW QUERY
                      </el-button>
                    </template>
                  </crud-field-select>
                </div>
              </div>
            </template>
            <template #fieldPrepend>
              <div class="absolute top-0 left-0 z-[1] hidden group-hover:block">
                <icon-ify
                  v-if="abData.abFilters.query?.length"
                  icon="carbon:close-outline"
                  class="gs-scaled-icon-sm inline-block -translate-y-1/2 cursor-pointer translate-x-[1px] active:text-blue-500"
                  @click="abData.abFilters.query = undefined"
                />
              </div>
            </template>
          </crud-field-textarea>
          <fields-col
            :sm="12"
            :gutter="0"
          >
            <crud-field-date-time
              api-field-name="start"
              :form="abData.abFilters"
              :sm="12"
              class="md:pr-0.5"
            />
            <crud-field-date-time
              api-field-name="end"
              :form="abData.abFilters"
              :sm="12"
            >
              <template #fieldPrepend>
                <span class="hidden md:inline-block pr-0.5">-</span>
              </template>
            </crud-field-date-time>
            <el-row>
              <el-button
                class="gs-btn-outlined-primary gs-height-related-lg font-related-sm pl-2"
                :loading="abData.loading.abResults"
                :icon="Filter"
                @click="loadAbResults(null)"
              >
                FILTER
              </el-button>
              <el-button
                class="gs-btn-outlined-neutral gs-height-related-lg font-related-sm pl-2 uppercase"
                :icon="Setting"
                @click="abData.showSegmentsDrawer = true"
              >
                Manage segments
              </el-button>
            </el-row>
          </fields-col>
        </el-row>
        <div class="mb-3" />
        <el-tabs
          v-model="abData.segmentTabsModel"
          :class="{ 'hide-tabs': segmentsKPIs.length < 2 }"
        >
          <el-tab-pane
            v-for="segmentTab in segmentsKPIs"
            :key="segmentTab.key"
            :name="segmentTab.key"
          >
            <template #label>
              <div @dblclick="abData.showSegmentsDrawer = true">
                <el-tooltip
                  v-if="segmentTab?.query?.length "
                  :show-after="300"
                  placement="bottom"
                >
                  <template #content>
                    Query: {{ segmentTab.query }}
                  </template>
                  {{ segmentTab.label }}
                </el-tooltip>
                <template v-else>
                  {{ segmentTab.label }}
                </template>
              </div>
            </template>
            <table
              class="border-collapse text-left text-cyan-600 text-sm"
            >
              <tbody>
                <tr
                  v-if="segmentTab.CalculatedFormat"
                  class="text-right text-xs"
                >
                  <td
                    :colspan="kpiOrderGroup.length + 1"
                    class=" py-0.5 italic"
                  >
                    calculated: {{ segmentTab.CalculatedFormat }}
                  </td>
                </tr>
                <tr class="bg-blue-100 text-cyan-600">
                  <th
                    class="px-1 py-1 font-normal min-w-10"
                  >
                    KPI
                  </th>
                  <th
                    v-for="grNumber in kpiOrderGroup"
                    :key="grNumber"
                    class="px-1 py-1 font-normal min-w-16"
                  >
                    <el-tooltip
                      raw-content
                      placement="bottom-start"
                      :show-after="300"
                    >
                      <template #content>
                        {{ form.segmentDescription?.[grNumber - 1] }}
                        <span
                          v-if="!form.segmentDescription?.[grNumber - 1]?.length"
                          class="capitalize"
                        >AB {{ groupLabel }} {{ grNumber }}</span>
                      </template>
                      <div class="max-w-44 truncate">
                        {{ form.segmentDescription?.[grNumber - 1] }}
                        <span
                          v-if="!form.segmentDescription?.[grNumber - 1]?.length"
                          class="capitalize"
                        >AB {{ groupLabel }} {{ grNumber }}</span>
                      </div>
                    </el-tooltip>
                  </th>
                </tr>
                <tr class="hover:bg-slate-100">
                  <td class="pl-1 pr-14 py-3 border-b border-b-slate-300">
                    Players
                  </td>
                  <td
                    v-for="gr in kpiOrderGroup"
                    :key="gr"
                    class="pl-1 pr-16 py-3 border-b border-b-slate-300"
                  >
                    {{ segmentTab.PlayersCompare[gr]?.qtFormat || 0 }}
                    <span
                      v-if="segmentTab?.PlayersCompare?.[gr]?.diffFormated?.length"
                      class="text-xs pl-0.5 whitespace-nowrap"
                    >({{ segmentTab.PlayersCompare[gr]?.diffFormated || 0 }})
                    </span>
                  </td>
                </tr>
                <tr
                  v-for="kpi in segmentTab.KPIs"
                  :key="kpi.key"
                  class="hover:bg-slate-100 even:bg-neutral-50"
                >
                  <td class="pl-1 pr-14 py-3 border-b border-b-slate-300">
                    <div class="truncate">
                      {{ kpi.Description }}
                    </div>
                  </td>
                  <td
                    v-for="gr in kpiOrderGroup"
                    :key="gr"
                    class="pl-1 pr-16 py-3 border-b border-b-slate-300"
                  >
                    {{ kpi?.Groups?.[gr]?.ValueFormated }}
                    <span
                      v-if="kpi?.Suffix"
                      class="px-0.5"
                    >{{ kpi?.Suffix }}</span>
                    <span
                      v-if="kpi?.Groups?.[gr]?.diffFormated?.length && !kpi?.Groups?.[gr]?.Continuous"
                      class="text-xs pl-0.5 whitespace-nowrap"
                      :class="{
                        'text-red-600': kpi?.Significant && kpi?.Groups?.[gr]?.diff < 0,
                        'text-green-600': kpi?.Significant && kpi?.Groups?.[gr]?.diff >= 0
                      }"
                    >({{ kpi?.Groups?.[gr]?.diffFormated }})
                      <icon-ify
                        v-if="kpi?.Significant && kpi?.Groups?.[gr]?.diff >= 0"
                        icon="material-symbols:check-circle"
                        class="gs-scaled-icon-md inline-block"
                      />
                      <icon-ify
                        v-else-if="kpi?.Significant && kpi?.Groups?.[gr]?.diff < 0"
                        icon="ep:circle-close-filled"
                        class="gs-scaled-icon-md inline-block"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-tab-pane>
        </el-tabs>
      </template>
      <HiPoSegmentsDrawer
        v-model:show-segments-drawer="abData.showSegmentsDrawer"
        :api-url="apiUrl"
        :segments-list="abData.abResultsData?.segmentsList"
        :segments-connected="abData.abResultsData?.segments || []"
        :game="game"
        :test-id="form.id"
        @refresh="loadAbResults($event)"
      />
      <HiPoQueryDrawer
        v-model:show-query-drawer="abData.showQueryDrawer"
        :query="abData.editQuery"
        :api-url="apiUrl"
        :game="game"
        :query-list="abData.abResultsData.queriesList"
        @refresh="onRefreshQueries($event)"
      />
    </template>
  </el-tab-pane>
</template>

<style scoped>
.query-field:deep(.el-form-item) {
  .el-form-item {
    &__label {
      padding: 0 0 0 5px !important;
    }

    &__content {
      position: relative;
      padding-left: 5px;
    }
  }
}
</style>
